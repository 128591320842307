import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function tokenomics() {
    return (
        <div className="py-140 pb-0">
            <Container id="roadmap">
                <Row className="justify-content-center mb-4  text-center">
                    <Col lg={12}>
                        <div className="section-title text-center">
                            <div>Upcoming Features</div>
                            <h3>Upcoming Features</h3>
                        </div>
                    </Col>

                    <Col lg={9} className="px-lg-4">
                        <p>
                        HedgePay is the centre of an ecosystem that overcomes traditional barriers between savings and liquidity. Spend your salary <sup>1</sup> on your daily needs while keeping your assets safe and increasing in value
                        </p>
                    </Col>
                </Row>

                <div className="feature-list">
                    <div className="feature-row d-md-flex justify-content-md-end">
                        <div className="feature-item">
                            <h4>Exclusive Investor Phase</h4>
                            <ul>
                                <li>Multi-Sign Security Wallet</li>
                                <li>Establishment of Community Communications</li>
                                <li>HedgeFi Fund Exclusive Investor Opportunity (EIO) Phase 1 to 4</li>
                                <li>Initial Liquidity Lock</li>
                                <li>HedgePay Rewards Compound Interest Phase</li>
                            </ul>
                        </div>
                    </div>
                    <div className="feature-row d-md-flex">
                        <div className="feature-item">
                            <h4>Foundation</h4>
                            <ul>
                                <li>Public Accessibility in Direct and Decentralized Exchanges</li>
                                <li>HedgePay Rewards</li>
                                <li>Buyback-powered Staking Protocol</li>
                                <li>HedgePay Bounty</li>
                                <li>Expanding the core investment strategies</li>
                                <li>Incorporation: Establishing HedgePay Legal Formalities </li>
                                <li>Public Relations Department</li>
                            </ul>
                        </div>
                    </div>
                    <div className="feature-row d-md-flex justify-content-md-end">
                        <div className="feature-item">
                            <h4>Accessibility Phase</h4>
                            <ul>
                                <li>HedgePay Mobile Wallet & Exchange App</li>
                                <li>HedgeFi Investment Protocol </li>
                                <li>First CEX Listings </li>
                                <li>Fiat Bridge, card purchase integration </li>
                                <li>Reward Mechanics Diversification</li>
                                <li>Solidify Online Presence</li>
                            </ul>
                        </div>
                    </div>
                    <div className="feature-row d-md-flex">
                        <div className="feature-item">
                            <h4>Expansion Phase</h4>
                            <ul>
                                <li>Crosschain Integration</li>
                                <li>HedgePay Labs: Payment processing gateway</li>
                                <li>HedgePay Labs: Staking as a service</li>
                                <li>HedgePay Labs: Liquidity lock service</li>
                                <li>HedgeFi: Collateral and Lending protocol</li>
                                <li>HedgeFi: Smart DEX</li>
                                <li>HedgeFi: Virtual Investors Lounge & Incubator</li>
                                <li>Brick and Mortar Presence</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default tokenomics;
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Tokens, useTokenContract } from '../../web3/contracts/contracts';
import PieChart from '../../images/pie-chart.svg';
import PieChartFee from '../../images/pie-chart-fees.svg';
import TickerSym from '../../images/ticker-sym.png';
import ChainIcon from '../../images/chain-icon.png';
import ExpandIcon from '../../images/expand-icon.png';
import CountUp from 'react-countup';
import { useHpayExchangePrice } from '../../web3/exchange';
import { useInterval } from 'react-use';


const useIsClient = () => {
    const [isClient, setClient] = useState(false);
    const key = isClient ? "client" : "server";

    useEffect(() => {
        setClient(true);
    }, []);

    return { isClient, key };
};

const Supply = () => {
    const { supply } = useTokenContract(Tokens.HPAY.address);

    return <>
        <CountUp isCounting end={supply} decimals={2} preserveValue={true} separator=',' />
    </>;
};


const Price = () => {
    const [price, setPrice] = useState(0);

    const fetchRates = useHpayExchangePrice();
   
    useEffect(() => {
        fetchRates(Tokens.HPAY, Tokens.BUSD).then(setPrice);
    }, [setPrice, fetchRates]);

    useInterval(async () => {
        const price = await fetchRates(Tokens.HPAY, Tokens.BUSD);
        setPrice(price);
    }, 10000);

    return <>
        <CountUp isCounting end={price} decimals={8} preserveValue={true} separator=',' />
    </>;

};

function Tokenomics() {
    const { isClient } = useIsClient();

    return (
        <div className="py-140 pb-0" id="economics">
            <Container >
                <Row className="justify-content-center mb-5 pb-md-4 text-center">
                    <Col md={10} lg={9} className="mb-4">
                        <div className="section-title">
                            <div>Economics</div>
                            <h3>Economics</h3>
                        </div>
                    </Col>
                    <Col lg={9} className="px-lg-4">
                        <p>The HedgeFi Fund utilizes decentralized finance investments to generate salaries<sup>1</sup> for our patrons to be rewarded on a monthly basis. Our patrons may use the rewards dashboard to claim their rewards earlier if desired. This will incur a pro-rated fee schedule that will be used to increase the capital of the HedgeFi Fund.</p>
                    </Col>
                </Row>

                <Row noGutters className="justify-content-center align-items-center">
                    <Col md={6} className="mb-md-0 mb-4">
                        <Row>
                            <Col xs={6} className="mb-3">
                                <div className="eco-group">
                                    <p>Ticker Symbol</p>
                                    <h5><img src={TickerSym} alt="HedgePay Finance Ticker" /> HPAY</h5>
                                </div>
                            </Col>
                            <Col xs={6} className="mb-3 text-right text-md-left">
                                <div className="eco-group">
                                    <p>Price</p>
                                    {isClient && <h5>$<Price></Price></h5>}
                                </div>
                            </Col>
                            <Col xs={6} className="mb-3">
                                <div className="eco-group">
                                    <p>Contract Address</p>
                                    <h5>0xc75aa...dfd521a
                                        <a href="https://bscscan.com/address/0xc75aa1fa199eac5adabc832ea4522cff6dfd521a/" target="_blank" rel="noopener noreferrer"> <img src={ExpandIcon} className="ml-2 mr-0" alt="icon" />
                                        </a>
                                    </h5>
                                </div>
                            </Col>
                            <Col xs={6} className="mb-3 text-right text-md-left">
                                <div className="eco-group">
                                    <p>Chain</p>
                                    <h5><img src={ChainIcon} alt="icon" /> BSC (BEP-20)</h5>
                                </div>
                            </Col>
                            <Col xs={6} className="mb-3">
                                <div className="eco-group">
                                    <p>Max Supply</p>
                                    <h5>1,000,000,000 HPAY</h5>
                                </div>
                            </Col>
                            <Col xs={6} className="mb-3 text-right text-md-left">
                                <div className="eco-group">
                                    <p>Circulating Supply</p>
                                    {isClient && <h5><Supply></Supply> HPAY</h5>}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <div className="text-center supply-distribution">
                        <img src={PieChart} alt="HedgePay (HPAY) distribution" className="img-fluid" />
                    </div>

                    <Col lg={8} >
                        <p className="info-sub-note mt-5">
                            * No initial development or reward tokens will be created. HedgePay will mint tokens incrementally, relative to on-chain mechanics to ensure the health of hedgepay economics.
                        </p>
                    </Col>
                </Row>

                <div className="feature-content w-100 " >
                    <Row noGutters className="justify-content-center text-center">

                        <Col lg={9} className="px-lg-4 mb-5 mt-5">
                            <p>
                                HedgePay uses revenue generated from each transaction to increase the capital of the HedgeFi Fund. Every blockchain transaction that involves transferring tokens is subjected to an 12%* fee. Transactions that involve interactions with HedgePay’s exchange will not incur this fee. The revenue generated will be allocated to the HedgeFi Fund to increase the digital salaries of our investors.
                            </p>
                        </Col>

                        <Col md={12} className="text-center supply-distribution">
                            <img src={PieChartFee} alt="HedgePay (HPAY) distribution" className="img-fluid" />
                        </Col>

                        <Col lg={8} >
                            <p className="info-sub-note mt-5">
                                * Sell transactions are subjected to a 12% fee.
                                <br />
                                * On-Chain transactions that do not interact with the HedgePay trading system are subjected to a 12% fee.
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}

export default Tokenomics;
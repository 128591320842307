import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Icon1 from '../../images/fr_1.png';
import Icon2 from '../../images/fr_2.png';
import Icon3 from '../../images/fr_3.png';
import Icon4 from '../../images/fr_4.png';
import Icon5 from '../../images/fr_5.png';
import Icon6 from '../../images/fr_6.png';
import RectCube from '../../images/rectCube.png';
import Sphere from '../../images/Sphere.png';

function features() {
    return (
        <div className="py-65 pb-0 text-center" id="how-it-works">
            <Container>
                <Row className="justify-content-center mb-3">
                    <Col md={10} lg={9}>
                        <div className="section-title text-center">
                            <div>Feature & Reward System</div>
                            <h3>Feature & Reward System</h3>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={4} className="mb-5">
                        <div className="icon-group">
                            <img src={Icon1} alt="Hedge Pay Crypto Finance" />
                            <p>HedgePay is the only step you need to become financially independent</p>
                        </div>
                    </Col>
                    <Col md={4} className="mb-5">
                        <div className="icon-group">
                            <img src={Icon2} alt="Hedge Pay Crypto Finance" />
                            <p>Made accessible, enhances safety, and autonomous by using fully decentralized blockchain technology</p>
                        </div>
                    </Col>
                    <Col md={4} className="mb-5">
                        <div className="icon-group">
                            <img src={Icon3} alt="Hedge Pay Crypto Finance" />
                            <p>Trade HedgePay using our exclusive in house exchange for the lowest fees on the decentralized market</p>
                        </div>
                    </Col>
                    <Col md={4} className="mb-5">
                        <div className="icon-group">
                            <img src={Icon4} alt="Hedge Pay Crypto Finance" />
                            <p>Stable coin powered liquidity and smart buyback mechanics ensures reduced price volatility and organic growth</p>
                        </div>
                    </Col>
                    <Col md={4} className="mb-5">
                        <div className="icon-group">
                            <img src={Icon5} alt="" />
                            <p>Free monthly digital rewards generated by automated financial strategies utilizing our proprietary risk-reduction algorithm</p>
                        </div>
                    </Col>
                    <Col md={4} className="mb-5">
                        <div className="icon-group">
                            <img src={Icon6} alt="Hedge Pay Crypto DeFi" />
                            <p>Safe & Secure: All  funds are secured via third party multiple signature wallets</p>
                        </div>
                    </Col>
                </Row>

                <div className="feature-content">
                    <p>HedgePay utilizes a token-based financial system to allow easy transactions. By simplifying the financial process and automating reward generation, HedgePay will increase access to earning opportunities in the decentralized finance industry.<br />
                    Our mission is to remove all entry barriers for our financial patrons and forge a safe path to consistent reward generation resistant to market volatility.
                    </p>

                    <img src={RectCube} className="feature-content-imgLeft" alt="Hedge Pay Crypto " />
                    <img src={Sphere} className="feature-content-imgRight" alt="Hedge Pay Icon " />
                </div>
            </Container>
        </div>
    );
}

export default features;